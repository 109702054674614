import './coupon.css'
import Layout from '../../../components/layout'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { getCouponList } from '../../../apis/coupon';
import { useQuery } from "@tanstack/react-query";
import Switch from '@mui/material/Switch';
import Spinner from '../../../components/spinner';
import Errors from '../../errors';
import AddCoupons from './AddCoupons';
import { useState } from 'react';
import DeleteCoupon from './DeleteCoupon';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import React from "react";
import EditCoupons from './EditCoupons';
import { useTableSearch } from '../../../hooks/useTableSearch';
import useReadUpdated from '../../../hooks/updated/useRead';
import useCreate from '../../../hooks/useCreate';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Coupons() {
    let initialData = {
        pageNo: 0,
        limit: 50,
        searchQuery: ''
    }
    const [openAddModal, setOpenAddModal] = useState(false)
    const { list } = useReadUpdated({
        url: 'couponlist',
        initialData
    })
    const handleOpenAddCoupon = () => {
        setOpenAddModal(true)
    }
    const handleModalClose = () => {
        setOpenAddModal(false)
        setOpenDeleteModal(false)
        setIsOpenEditModal(false)
    }
    const [couponId, setCouponId] = useState(0)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const handleOpenDeleteModal = (id) => {
        setOpenDeleteModal(true)
        setCouponId(id)
    }
    const [isDeletedSuccess, setIsDeletedSuccess] = useState(false)
    const deletedSuccess = () => {
        handleModalClose()
        setIsDeletedSuccess(true)
    }

    const [editData, setEditData] = useState({})
    const [isOpenEditModal, setIsOpenEditModal] = useState(false)
    const handleOpenEditModal = (id) => {
        setIsOpenEditModal(true)
        let data = list.data.data.find(x => x.id === id)
        setEditData(data)
    }

    const handleSearchVal = (e) => {
        let value = e.target.value
        // setSearcVal(value)
    }

    const [productVisibiliyId, setProductVisibiltyId] = useState(0)
    const { setDataToServer } = useCreate({
        url: 'couponstatus',
        refreshUrl: 'couponlist',
        onSuccess: () => {
            setProductVisibiltyId(0)
        }
    })
    const handleChangeStatus = (e, id) => {
        setProductVisibiltyId(id)
        let status = e.target.checked
        let data = {
            id,
            status
        }
        setDataToServer.mutate(data)
    }
    return (
        <>
            <div className='category px-4'>
                <div className='category__head headingBorder__b'>
                    <h2 className='titleHeading'>Coupons</h2>
                </div>
                <div className='category_table py-5 space-y-5'>
                    <div className='flex items-center space-x-4'>
                        <div className='flex-1'>
                            <input onChange={handleSearchVal} type="text" placeholder='Search Coupons....' className='input' />
                        </div>
                        <div onClick={handleOpenAddCoupon}>
                            <Button variant="outlined" startIcon={<AddIcon />} className='primaryBtn-outlined'>
                                Add Coupon
                            </Button>
                        </div>
                    </div>
                    {list.isLoading ? <Spinner />
                        : list.status === "error" ? <Errors errorObject={list.error} inline /> :
                            <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                    <thead>
                                        <tr className="text-left">
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Sr.no
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                Coupon Name
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                Coupon Code
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                Discount Percentage/Amount
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                Coupon Valid From
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                Coupon Valid Till
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                Visibility
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list.data?.data?.map((couponData, idx) => {
                                            const { id, couponName, couponCode, couponDiscountAmount, couponValidFrom, couponValidTill, status } = couponData
                                            return <tr key={id}>
                                                <td className="border-dashed border-t border-gray-200 userId">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                        {idx + 1}
                                                    </span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 firstName">
                                                    <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                        {couponName}
                                                    </span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 lastName">
                                                    <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                        {couponCode}
                                                    </span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 lastName">
                                                    <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                        {couponDiscountAmount}
                                                    </span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 lastName">
                                                    <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                        {couponValidFrom}
                                                    </span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 emailAddress">
                                                    <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                        {couponValidTill}
                                                    </span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 emailAddress">
                                                    <div className='flex items-center justify-center'>
                                                        {id === productVisibiliyId ? <Spinner className={'w-8 h-8'} /> :
                                                            <>
                                                                <span className='text-gray-700 text-sm'>Hide</span>
                                                                <Switch inputProps={{ 'aria-label': 'controlled' }} onChange={(e) => handleChangeStatus(e, id)} className='hide_show_switch' checked={status} />
                                                                <span className='text-gray-700 text-sm'>Show</span></>}
                                                    </div>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                    <div className='flex items-center space-x-5 justify-center'>
                                                        <div>
                                                            <IconButton onClick={() => handleOpenEditModal(id)}>
                                                                <EditIcon />
                                                            </IconButton>
                                                        </div>
                                                        {/* <div onClick={() => handleOpenDeleteModal(id)}>
                                                            <IconButton>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </div> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>}
                </div>
            </div>
            <AddCoupons open={openAddModal} handleClose={handleModalClose} />
            <EditCoupons open={isOpenEditModal} handleClose={handleModalClose} editData={editData} />
            <DeleteCoupon open={openDeleteModal} onClose={handleModalClose} id={couponId} onDelete={deletedSuccess} />
            <Snackbar open={isDeletedSuccess} autoHideDuration={4000} onClose={() => setIsDeletedSuccess(false)}>
                <Alert onClose={() => setIsDeletedSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Category Deleted Succesfully
                </Alert>
            </Snackbar>
        </>
    )
}

export default Coupons