import Layout from "../../components/layout"
import CardWidgets from "../../components/widgets/CardWidgets"
import { useQuery } from "@tanstack/react-query";
import { getDashboardData } from "../../apis/dashboard";
import Spinner from "../../components/spinner";
import Errors from "../errors";
import { PROJECT_NAME } from "../../config";
function Dashboard() {
    const handleGetDashboardData = useQuery(['categoryList'], getDashboardData, {
        refetchOnWindowFocus: true
    })
    return (
        <>
            <div className=" px-4">
                {handleGetDashboardData.isLoading ? <Spinner />
                    : handleGetDashboardData.status === "error" ? <Errors errorObject={handleGetDashboardData.error} inline /> :
                        <div className="grid grid-cols-4 gap-x-5 mt-8">
                            <CardWidgets
                                label={'orders'}
                                description={`Number of orders On ${PROJECT_NAME} website`}
                                border={` border-red-700`}
                                count={handleGetDashboardData.data.data.total_orders || 0}
                            />
                            <CardWidgets
                                label={'customers'}
                                description={`Number of customers on ${PROJECT_NAME} website`}
                                border={` border-blue-700`}
                                count={handleGetDashboardData.data.data.total_customers || 0}
                            />
                            <CardWidgets
                                label={'products'}
                                description={`Number of products on ${PROJECT_NAME} website`}
                                border={` border-lime-700`}
                                count={handleGetDashboardData.data.data.total_products || 0}
                            />
                            <CardWidgets
                                label={'revenue'}
                                description={`Revenue generated by ${PROJECT_NAME} website`}
                                border={` border-yellow-700`}
                                count={handleGetDashboardData.data.data.total_paid_orders || 0}
                            />
                        </div>}
            </div>
        </>
    )
}

export default Dashboard