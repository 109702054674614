import { SecondaryButton } from '../../../components/elements/buttons'
import Tree from '../../../components/section/tree'
import "./index.css";
import CategoryForm from "./CategoryForm";
import useRead from '../../../hooks/useRead';
import { useState, useRef } from 'react';

const Category = () => {
  const categoryFormRef = useRef(null)
  const initialData = {
    pageNo: 0,
    limit: 50,
    searchQuery: ''
  }
  const { data } = useRead({ initialData, url: 'categorylist' })
  const [selectedNode, setSelectedNode] = useState(null)
  const handleSelectNode = (_, value) => {
    let selectedData = data.data?.data.find(x => x.name === value)
    setSelectedNode(selectedData)
    categoryFormRef.current.setEditData(selectedData)
  }

  const handleCreateRootCategory = () => {
    categoryFormRef.current.handleResetForm()
  }
  return (
    <div>
      <div className='flex items-center justify-between bg-body_bg shadow px-4 py-2'>
        <h2 className=' font-semibold text-3xl'>{selectedNode?.name || 'Category'}</h2>
      </div>
      <div className='px-4 py-10'>
        <div className='flex items-start -mx-8'>
          <div className='basis-[30%] px-8'>
            <div className=' space-y-2'>
              <div>
                <SecondaryButton onClick={handleCreateRootCategory} className=' text-sm' label={'Add Root Category'} />
              </div>
              <div>
                <SecondaryButton className=' text-sm' label={'Add Subcategory'} />
              </div>
            </div>
            <div className='mt-5'>
              <Tree data={data.data?.data} onPress={handleSelectNode} />
            </div>
          </div>
          <div className='basis-[70%] px-8'>
            <CategoryForm ref={categoryFormRef} data={selectedNode} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Category