import { IoCloseSharp } from 'react-icons/io5'
import { NavLink } from 'react-router-dom'

const SubMenu = ({ open, onClose, data }) => {
    const { screen, sub_link } = data
    return (
        <div className={`bg-gray-200 fixed h-full duration-300 shadow-right z-[-1] top-0 ${open ? 'ml-[100px] w-[250px]' : '-ml-[500px]'}`}>
            <div className='p-5'>
                <div className='flex items-center justify-between'>
                    <h2 className='text-xl font-semibold uppercase'>{screen}</h2>
                    <IoCloseSharp className=' w-6 h-6 cursor-pointer' onClick={onClose} />
                </div>
                <div className=' mt-10'>
                    {sub_link?.map((subLinkItem, subLinkIdx) => {
                        const { screen, link } = subLinkItem
                        return <NavLink onClick={onClose} key={subLinkIdx} to={link} className={({ isActive }) => `w-full mb-3 py-3 inline-block relative z-40 hover:bg-gray-100 ${isActive ? 'bg-white' : ''}`}>
                            <span className='text-xs font-semibold uppercase'>{screen}</span>
                        </NavLink>
                    })}
                </div>
            </div>
        </div>
    )
}

export default SubMenu