import './App.css';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import {Toaster} from 'react-hot-toast'
import Login from './pages/auth/Login';
import Dashboard from './pages/dashboard';
import Category from './pages/catalog/category';
import Products from './pages/catalog/products';
import Services from './pages/services';
import Orders from './pages/sales/orders';
import Coupons from './pages/customers/coupons';
import ProtectedRoutes from './routes/ProtectedRoutes';
import Reports from './pages/reports';
import Blogs from './pages/blogs';
import AddProduct from './pages/catalog/products/AddProduct';
import Taxes from './pages/taxes';
import Brands from './pages/brands';
import Frames from './pages/frames';
import EditProduct from './pages/catalog/products/EditProduct';
import Lens from './pages/lens';
import LensSize from './pages/lensSize';
import LenSizeMapping from './pages/lensSizeMapping';
import Layout from './components/layout/index'
import SalesRoute from './routes/SalesRoute';
import ScrollTop from './routes/ScrollTop';
import Customers from './pages/customers/customersList';


const queryClient = new QueryClient()
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster/>
      <Router>
        <ScrollTop/>
        <Routes>
          <Route path='/login' exact element={<Login />} />
          <Route path='/' exact element={<Login />} />
          <Route element={<ProtectedRoutes />}>
            <Route element={<Layout />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path='sales/orders' exact element={<Orders />} />
              <Route path='sales/invoices' exact element={<Dashboard />} />
              <Route path='sales/returns' exact element={<Dashboard />} />
              <Route path='catalog/category' exact element={<Category />} />
              <Route path='catalog/products' exact element={<Products />} />
              <Route path='catalog/products/add-product' exact element={<AddProduct />} />
              <Route path='catalog/products/edit-product/:id' exact element={<EditProduct />} />
              <Route path='customers/coupons' exact element={<Coupons />} />
              <Route path='customers/all-customers' exact element={<Customers />} />
            </Route>
          </Route>
          {/* <Route path='/dashboard' exact element={<ProtectedRoutes><Dashboard /></ProtectedRoutes>} />
          <Route path='/category' exact element={<ProtectedRoutes><Category /></ProtectedRoutes>} />
          <Route path='/products' exact element={<ProtectedRoutes><Products /></ProtectedRoutes>} />
          <Route path='/products/add-product' exact element={<ProtectedRoutes><AddProduct /></ProtectedRoutes>} />
          <Route path='/products/:id' exact element={<ProtectedRoutes><EditProduct /></ProtectedRoutes>} />
          <Route path='/services' exact element={<ProtectedRoutes><Services /></ProtectedRoutes>} />
          <Route path='/orders' exact element={<ProtectedRoutes><Orders /></ProtectedRoutes>} />
          <Route path='/lens' exact element={<ProtectedRoutes><Lens /></ProtectedRoutes>} />
          <Route path='/size' exact element={<ProtectedRoutes><LensSize /></ProtectedRoutes>} />
          <Route path='/lens-size-mapping' exact element={<ProtectedRoutes><LenSizeMapping /></ProtectedRoutes>} />
          <Route path='/customers' exact element={<ProtectedRoutes><Customers /></ProtectedRoutes>} />
          <Route path='/coupons' exact element={<ProtectedRoutes><Coupons /></ProtectedRoutes>} />
          <Route path='/reports' exact element={<ProtectedRoutes><Reports /></ProtectedRoutes>} />
          <Route path='/blogs' exact element={<ProtectedRoutes><Blogs /></ProtectedRoutes>} />
          <Route path='/taxes' exact element={<ProtectedRoutes><Taxes /></ProtectedRoutes>} />
          <Route path='/brands' exact element={<ProtectedRoutes><Brands /></ProtectedRoutes>} />
          <Route path='/frames' exact element={<ProtectedRoutes><Frames /></ProtectedRoutes>} /> */}
        </Routes>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
