import React,{useState} from 'react'
import ReactPaginate from 'react-paginate'
function Pagination({ lengthofItems, onPageChange, limit, currentPage }) {
    const [value, setValue] = useState({
      selected: currentPage + 1,
    });
  
    const handleValueChange = (e) => {
      setValue({ selected: e.target.value });
    };
  
    const handleInputPageChange = (e) => {
      e.preventDefault();
      onPageChange({
        selected: parseInt(value.selected) - 1,
      });
      return;
    };
  
    return (
      <div className="flex flex-wrap space-y-5 items-center justify-between sm:space-y-0  md:flex-nowrap md:space-x-5 lg:space-y-0 xl:space-y-0">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={onPageChange}
          pageRangeDisplayed={50}
          pageCount={Math.ceil(lengthofItems / limit)}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="flex items-center justify-center space-x-4"
          pageLinkClassName="pageNumber"
          previousLinkClassName="pageNumber"
          nextLinkClassName="pageNumber"
          activeLinkClassName="selectedPageNumber"
          disabledClassName="lastPage"
          disabledLinkClassName="lastPage"
          forcePage={currentPage}
          
        />
        
        <div>
          <form onSubmit={handleInputPageChange} className="flex">
           <label>Go To:</label>
            <input
              min={1}
              max={Math.ceil(lengthofItems / limit)}
              type="number"
              onChange={handleValueChange}
              className="border py-1 px-3 min-w-[2rem] max-w-[5rem] ml-6"
              value={value.selected} 
            />
            <button
              type="submit"
              className="border ml-2 py-1 px-3 w-max bg-[#241e4e] text-white"
            >
              Go
            </button>
          </form>
        </div>
      </div>
    );
  }

export default Pagination