import { useState, forwardRef, useImperativeHandle, useRef } from "react";
import { PrimaryButton } from "../../../components/elements/buttons"
import Input from "../../../components/elements/inputs/Input"
import SwitchToggle from "../../../components/elements/switchtoggle/SwitchToggle"
import useCreate from "../../../hooks/useCreate";
import { AiOutlinePlus } from 'react-icons/ai'
import useUploadMedia from "../../../hooks/file/useUploadMedia";
import Loader from "../../../components/loader";
import toast from "react-hot-toast"

const CategoryForm = forwardRef(({ data }, ref) => {
    const fileInput = useRef(null)
    const handleResetForm = () => {
        setForm(initialData)
        setFilePreview(null)
    }
    const setEditData = (data) => {
        setForm(data)
    }
    useImperativeHandle(ref, () => ({
        handleResetForm, setEditData
    }))
    let initialData = {
        status: false,
        include_menu: false,
        name: "",
        seo: {
            meta_title: '',
            meta_keywords: '',
            meta_desc: ''
        },
        thumbnail: {
            image_id: '',
            image_alt: '',
        }
    }
    const [filePreview, setFilePreview] = useState(null);
    const [form, setForm] = useState(initialData);
    const handleChangeChecked = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.checked
        });
    };
    const handleForm = (name, value) => {
        setForm({ ...form, [name]: value });
    }
    const handleSeoForm = (e) => {
        let name = e.target.name
        let value = e.target.value
        let seoMetaForm = form
        seoMetaForm.seo[name] = value
        setForm({ ...form })

    }
    const { upload } = useUploadMedia({
        url: 'uploadImage',
        refreshUrl: '',
        onSuccess: (res) => {
            const { image_url, image_id } = res
            let formDataCopy = form
            formDataCopy['thumbnail'].image_id = image_id
            setFilePreview(image_url)
            setForm({ ...form })
        }
    })
    const handleFormFile = (e) => {
        let thumbnail = e.target.files[0]
        let data = {
            screen: 'categorys',
            thumbnail: [thumbnail]
        }
        upload.mutate(data)
    }
    const { setDataToServer } = useCreate({
        url: 'categorycreate',
        refreshUrl: 'categorylist',
        onSuccess: () => {
            handleResetForm()
            toast.success("Category Added Successfully",{
                position:"top-right",
                duration:3000
            })
        }
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(form);
        setDataToServer.mutate(form)
    }
    const handleAltTag = (name, value, object) => {
        let formDataCopy = { ...form }
        formDataCopy[object][name] = value
        setForm({ ...form })
    }

    return (
        <>
            {upload.isLoading && <div className='fixed w-full h-full bg-[#ffffff70] top-0 left-0 z-50'>
                <div className='flex w-full items-center justify-center h-full'>
                    <Loader className={'text-black !w-12 !h-12'} />
                </div>
            </div>}
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="flex justify-end mt-5 space-x-3">
                    <PrimaryButton label={'Save'} type={"submit"} />
                </div>
                <div className="border-b-2 border-black pt-2 pb-2">
                    <span className="text-2xl font-normal">Currently Active</span>
                </div>
                <div className="py-5 grid grid-cols-2">
                    <div>
                        <SwitchToggle checkedValue={form.status} label={"Enable Category"} onChange={(e) => handleChangeChecked(e)} name="status" />
                    </div>
                    <div>
                        <SwitchToggle checkedValue={form.include_menu} label={"Include in Menu"} onChange={(e) => handleChangeChecked(e)} name="include_menu" />
                    </div>
                </div>
                <div>
                    <div className="mb-6 ">
                        <Input
                            label={"Category Name"}
                            required={"required"}
                            placeholder={"e.g. Clothing"}
                            name={"name"}
                            value={form?.name}
                            onChange={(e) => handleForm('name', e.target.value)}
                        />
                    </div>
                </div>

                <div className="border-b-2 border-black pt-2 pb-2">
                    <span className="text-2xl font-normal">Content</span>
                </div>

                <div>
                    <label className="label my-4">Category Image</label>
                    <div className="">
                        <input type="file" ref={fileInput} id="fileUpload" className="hidden" onChange={(e) => handleFormFile(e)} name="image" />
                        <button type="button" className=" hover:bg-gray-200 hover:shadow-md border rounded-md border-dashed border-gray-300 w-32 py-4 bg-gray-100" onClick={() => fileInput.current.click()}>
                            <div className="flex items-center justify-center flex-col">
                                <AiOutlinePlus className="w-10 h-10 text-gray-500" />
                                <span className=" text-gray-600">Add Image</span>
                            </div>
                        </button>
                    </div>
                    {(filePreview || form.image_path) &&
                        <div>
                            <div className="w-2/4 ml-auto mr-auto mt-5 mb-5">
                                <img src={filePreview || form.image_path} alt="image_preview" />
                            </div>
                            <Input
                                label={"Image alt"}
                                required={"required"}
                                placeholder={"Image alt"}
                                name={"image_alt"}
                                value={form?.thumbnail?.image_alt}
                                onChange={(e) => handleAltTag('image_alt', e.target.value, 'thumbnail')}
                            />
                        </div>
                    }
                </div>


                <div className="border-b-2 border-black pt-2 pb-2">
                    <span className="text-2xl font-normal">Search Engine Optimization</span>
                </div>

                <div>
                    <div className="pt-6 pb-6  space-y-9">
                        <Input
                            label={"Meta Title"}
                            placeholder={"Meta title"}
                            name={"meta_title"}
                            value={form.seo?.meta_title}
                            onChange={handleSeoForm}
                        />

                        <Input
                            label={"Meta Keywords"}
                            placeholder={"meta keywords"}
                            name={"meta_keywords"}
                            value={form.seo?.meta_keywords}
                            onChange={handleSeoForm}
                        />
                        <Input
                            label={"Meta Description"}
                            placeholder={"meta description"}
                            name={"meta_desc"}
                            value={form.seo?.meta_desc}
                            onChange={handleSeoForm}
                        />
                    </div>

                </div>
            </form>
        </>
    )
})

export default CategoryForm